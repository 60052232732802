import React from "react";
import Header from "../../components/Header/Header";
import cognitoUtils from '../../lib/cognitoUtils'
import { connect } from 'react-redux'
import Spinner from "react-bootstrap/Spinner";
import appConfig from '../../config/app-config.json';
import * as Constants from "../../components/Constants";
import "./Home.css";
import { initSessionFromCallbackURI } from '../../actions/session';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from 'query-string';
import Resize from "../../components/Resize/Resize";
import Footer from "../../components/Footer/Footer";

const mapStateToProps = state => {
  return { session: state.session }
}

function mapDispatchToProps (dispatch) {
  return {
    initSessionFromCallbackURI: href => dispatch(initSessionFromCallbackURI(href))
  }
}

class Home extends React.Component {
  constructor (props) {
    super(props)
    // Set the environment
    let environment = (window.location.hostname === "localhost") ? 
    "local" : window.location.hostname.substring(9 , window.location.hostname.indexOf('.'));
    if (environment==="") environment="prod";
    const heightAval=window.innerHeight-394.72;

    this.state = {
      sessionPopulated: false,
      environment,
      heightAval,
    }
  }

  getConfiguration = (email) => {
    const appConfigEnt = appConfig[this.props.session.environment];
    let configUrlApi = `${appConfigEnt.apiUri}/inventory/configuration?eid=` + 
    email.substring(0, email.lastIndexOf("@"));
    // Tracear el access_token
    console.log('id_token: ');
    console.log(qs.parse(window.location.hash).id_token);
    // Tracear el access_token
    console.log('access_token: ');
    console.log(qs.parse(window.location.hash).access_token);
        
    Axios.get(configUrlApi, {
      method: 'GET',
      responseType: 'json',
        headers: {
          Authorization: qs.parse(window.location.hash).access_token,
          'Content-Type': 'application/json'
        },
    },
    ).then(res => {
      // Set in Session to share in all App
      const user={
        active: res.data.active,
        eid: res.data.eid,
        role: res.data.role,
        apK: res.data.accessApK,
        access_token: res.config.headers.Authorization
      };
      this.props.session.user = user;
      this.props.session.listTopPills = res.data.listTopPills;
      this.props.session.listActualTags = res.data.listActualTags;

      // Warming Search for this user
      //const appConfigEnt = appConfig[this.props.session.environment];
      const url = `${appConfigEnt.apiUri}/inventory/list?loggedEid=` + user.eid + 
      "&status=APPROVED&keywords='ALL'";
      Axios.get(url, {
        method: 'GET',
        responseType: 'json',
          headers: {
            Authorization: user.access_token,
            'Content-Type': 'application/json',
            'x-api-key': user.apK,
          },
      },
      ).then(res => {
      }).catch(err => { 
      });
      // END Warming Search
  
      // Set state in order to render the Component
      this.setState({ sessionPopulated: true });
    }).catch(err => { 
      console.log(err);
      this.notify("error", "Something was wrong. Please, try again!");
    });
  };

  // Handler Window's Resize
  handleResize = (width, height) => {
    const heightAval=height-394.72;
    this.setState({
      heightAval 
    });
  };
  
  componentDidMount () {
    const {environment} = this.state;
    // Set session's environment
    this.props.session.environment = environment;
    if (!this.props.session.isLoggedIn) {
      let cognitoUri;
      switch (environment) {
        case Constants.localEnvironment :
          cognitoUri = cognitoUtils.getCognitoSignInUriCog(environment);
          break;
        case Constants.developEnvironment :
          cognitoUri = cognitoUtils.getCognitoSignInUriCog(environment);
          break;
        case Constants.stagingEnvironment:
          cognitoUri = cognitoUtils.getCognitoSignInUriFed(environment);
          //cognitoUri = cognitoUtils.getCognitoSignInUriCog(environment);
          break;
        case Constants.productionEnvironment:
          cognitoUri = cognitoUtils.getCognitoSignInUriFed(environment);
          break;
        default:
          cognitoUri = cognitoUtils.getCognitoSignInUriCog(environment);
      }
      window.location.replace(cognitoUri);
    } else {
      let token = this.props.location.hash;
      if (token==="") window.location.reload();
      let decoded = jwt_decode(token);
      let email="";
      // GET APP CONFIGURATION FROM EMAIL (depend on environment)
      switch (environment) {
        case Constants.localEnvironment :
          email = decoded.email;
          break;
        case Constants.developEnvironment :
          email = decoded.email;
          break;
        case Constants.stagingEnvironment:
          //email = decoded.email;
           email = decoded.username.substring(
            decoded.username.indexOf("_")+1, decoded.username.length);
          break;
        case Constants.productionEnvironment:
          email = decoded.username.substring(
            decoded.username.indexOf("_")+1, decoded.username.length);
          break;
        default:
          email = decoded.email;
          break;
      }
      this.getConfiguration(email);
    };
    window.location.hash = "";
  }
  
  //Toasts
  notify = (type, msg) => toast[type]((type === "error" ? "" : " ") + msg);

  render() {
    const { session } = this.props;
    const {sessionPopulated, heightAval} = this.state;

    if ((!sessionPopulated) || (!session.isLoggedIn))
      return ( 
        <div className="Home">
          <Spinner animation="border" style={{color: "white"}}></Spinner>
        </div>
      )
    else if (!session.user.active) {
      return (
        <div className="Home">
          <Resize changeResize={this.handleResize}/>
          <Container className="container-lg" style={{"maxWidth": "85%"}}>
            <Header />
            <div className="link-home-small">
              <p>You enterprise id ({session.user.eid}) is not active in Inventory, please request your access to the tool sending an email to marketing team <a href="mailto:spainatc.comms@accenture.com?subject=Inventory - Request access to inventory">here</a></p>
            </div> 
            <div>
              <img src="/images/pixelt.png" alt="" width="1" height={heightAval} border="0"/>
            </div>          
            <Footer />
          </Container>
        </div>
      );
    } else if (session.user.role!=='FULL') 
      return <Redirect to={session.user.role} />
    else
    // Cambio: Si es FULL en vez de a HOME, redirigimos a DONWLOAD
    return <Redirect to='DOWNLOAD' />
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(Home)